import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import CarLoader from "./carLoader";
import axios from "axios";
import { message } from "antd";

export const configJSON = require("../Components/Config");
const Category = () => {
  const [isLoader, setIsLoader] = useState();
  const [isAddCat, setIssAddCat] = useState(false);
  const [categories, setCategories] = useState([]);
  const [isEditCat, setIsEditCat] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [categoryID, setCategoryID] = useState();
  useEffect(() => {
    getCategories();
  }, []);
  const getCategories = () => {
    setIsLoader(true);
    axios({
      url: configJSON.baseUrl + configJSON.categories,
      method: "get",
    })
      .then((res) => {
        if (res?.data?.success == true) {
          setIssAddCat(false);
          setCategories(res?.data?.categories);
          setIsLoader(false);
        } else {
          // message.error(res?.data?.message)
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(()=>{
        setIsLoader(false)
      })
  };


  const handleDeleteCategory = (id) => {
    setIsLoader(true);
    axios({
      url: configJSON.baseUrl + configJSON.deleteCategoryAPI,
      method: "post",
      data: {id : id},      
    })
      .then((res) => {
        if (res?.data?.success === true) {
          message.success(res?.data?.message);
          getCategories(); 
        } else {
          message.error(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Failed to delete the category!");
      })
      .finally(() => setIsLoader(false));
  };


  const CreateCategory = () => {
    const handleInputChange = (e) => {
      setCategoryName(e.target.value);
    };
    
    const handleSubmit = async (e) => {
      setIsLoader(true);
      e.preventDefault();
      if (!categoryName.trim()) {
        message.error("Category name cannot be empty");
        return;
      }

      const apiUrl = isEditCat
      ? `${configJSON.baseUrl + configJSON.editCategoryAPI}`
      : configJSON.baseUrl + configJSON.addCategory;

      const data = isEditCat ?  {id : categoryID , name: categoryName} : { name: categoryName };

      axios({
        url: apiUrl,
        method: "post",
        data: data,
      })
        .then((res) => {
          if (res?.data?.success == true) {
            message.success(res?.data?.message);
            getCategories();
            setIsEditCat(false); 
            setCategoryName(""); 
          } else {
            message.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err);
          message.error(err?.response?.data?.message)
        })
        .finally(() => {
          setIsLoader(false);
        });
    };

    return (
      <>
      <div className="container">
        <div className="row">
          <div className="col-lg-7 mx-auto">
          <div className="ct_profile_main mt-5">
          <h4>Category Detail</h4>
          <div className="form-group mb-4">
            <label for="" className="mb-2">
              Category
            </label>
            <form>
            <div className="position-relative">
              <input
                type="text"
                value={categoryName}
                onChange={handleInputChange}
                className="form-control"
                autoFocus // This will focus the input when the form is rendered
              />
            </div>
            </form>
          </div>

          {/* {isAddCat && (
            <button
              type="submit"
              className="ct_custome_btn mt-4"
              onClick={handleSubmit}
            >
              Create
            </button>
          )}
          {isEditCat && (
            <button
              type="submit"
              className="ct_custome_btn mt-4"
              onClick={handleSubmit}
            >
              Update
            </button>
          )} */}

           <button
              type="submit"
              className="ct_custome_btn mt-4"
              onClick={handleSubmit}
            >
             { isEditCat ? "Update" : "Create"}
            </button>
        </div>

          </div>

        </div>

      </div>
      </>
    );
  };

  return (
    <>
      <section className="ct_padd_in_100">
        <Navbar data="category" />

        {!isAddCat && !isEditCat && (
          <div className="row mt-5">
            <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between mb-4">
              <h4>Category</h4>
              <button
                class="ct_custome_btn py-2 ct_btn_border_10 w-auto py-2"
                onClick={() => setIssAddCat(true)}
              >
                <i class="fa-solid fa-plus me-1"></i> Add Category
              </button>
            </div>
            <div className="ct_location_table mb-4">
              {isLoader && <CarLoader />}
              {!isLoader && (
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Category</th>
                      <th className="text-end">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {categories?.length != 0 ? (
                      categories?.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item?.name}</td>
                          <td className="d-flex justify-content-end ">
                            <div className="d-flex align-items-center gap-2">
                              <button
                                className="ct_edit_tbl_icon"
                                onClick={() => {
                                  setCategoryID(item?.id);
                                  setCategoryName(item.name);
                                  setIsEditCat(true);
                                }}
                              >
                                <i class="fa-solid fa-pen"></i>
                              </button>
                              <button 
                              className="ct_dlt_tbl_icon"
                              onClick={() => handleDeleteCategory(item?.id)}
                              >
                                <i class="fa-solid fa-trash-can"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <h3 className="text-center">No data found</h3>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        )}
      </section>

      {/* Add Category */}
      {/* {(isAddCat || isEditCat) && <CreateCategory />} */}
      {isEditCat && <CreateCategory />}
    </>
  );
};

export default Category;
